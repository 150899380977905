<template>
  <div
    v-if="isItemsAvailable"
    id="w-node-_86076336-b251-8e3e-88cb-7b7d9fd60a7b-08e05dff"
    data-w-id="86076336-b251-8e3e-88cb-7b7d9fd60a7b"
    class="order-box_component buy"
  >
    <cart-summary style="position:sticky"></cart-summary>
    <div class="order-box_content-wrapper">
      <n-button :disabled="disabled" type="primary" size="large" @click="showModal = true"
        >Buy Now</n-button
      >
      <div class="text-size-small text-align-center">
        <n-alert v-if="disabled" type="warning">
          Please complete all the items in your cart before checking out.
        </n-alert>
      </div>
      <div class="text-size-small text-align-center">
        By placing your order you agree to PitchBridge&#x27;s
        <a
          href="https://pitchbridge.com/legal/terms-and-conditions"
          class="text-style-link"
          >Terms and Conditions</a
        >.
      </div>
    </div>
    <div class="order-box_content-wrapper is-highlighted">
      <div class="text-align-center">30-day money back guarantee</div>
    </div>
  </div>
  <n-modal v-model:show="showModal">
    <n-card
      class="modal-card"
      title="Pick your payment method"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <n-space vertical size="large">
        <PaymentMethodButton method="stripe" />
        <PaymentMethodButton method="paypal" />
      </n-space>
    </n-card>
  </n-modal>
</template>

<script>
import { NModal, NCard, NSpace, NButton, NAlert } from "naive-ui";
import { useAppStore } from "../store/app";
import { storeToRefs } from "pinia";
import CartSummary from "./CartSummary.vue";
const regix = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export default {
  setup() {
    const store = useAppStore()
    const { campaignData } = storeToRefs(store);
    return {
      store,
      campaignData
    }
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    disabled () {
      return this.isCampaignFormValid() && this.isCampaignServiceFormValid() && this.campaignData.status === "draft" && this.campaignData.items.length !== 0? false : true;
    },

    isItemsAvailable () {
      return this.campaignData !== null && this.campaignData.status !== null && this.campaignData.status === 'draft' && this.campaignData.items.length > 0;
    }
  },
  methods: {
    isCampaignFormValid () {
      const metadata = this.campaignData ? this.campaignData.metadata : null;
      const isValid = metadata && metadata.spotifyArtistId && regix.test(metadata.spotifyArtistId) ? true : false;
      return  isValid
    },
    isCampaignServiceFormValid () {
      const items = this.campaignData ? this.campaignData.items : [];
      const isValid = items.every( item => {
        let validStatus = false;
        switch (item.service.id) {
          case 1:
            validStatus = this.isValidStreamServices(item)
            break;
          case 2:
            validStatus = this.isValidYouTubeServices(item)
            break
          case 3:
            validStatus = this.isSocialMediaFormValid(item)
            break;
          case 4:
            validStatus = this.isFirstTimerPackValid(item)
            break;
          default:
            break;
        }
        return validStatus;
      })
      return isValid
    },
    isValidCommonField (item) {
      const {metadata, cost} = item;
      const {endDate, startDate} = metadata;
      return cost.net && !cost.net.toString().includes(".") && endDate && startDate ? true : false;
    },
    isValidStreamServices (item) {
      const {links, uploadcareId} = item.metadata.content;
      return this.isValidCommonField(item)  && links.song  && regix.test(links.song) && uploadcareId ? true : false;
    },
    isValidYouTubeServices (item) {
      const {links} = item.metadata.content;
      return this.isValidCommonField(item)  && links.song && regix.test(links.song) ? true : false;
    },
    isFirstTimerPackValid (item) {
      const {endDate, startDate, content} = item.metadata;
      return endDate && startDate && content.uploadcareId ? true : false;
    },
    isSocialMediaFormValid (item) {
      const {links} = item.metadata.content;
      const isValid = this.isValidCommonField(item) && links.facebook && regix.test(links.facebook) && links.instagram  && regix.test(links.instagram) && links.tiktok && regix.test(links.tiktok)? true : false;
      return isValid
    }
  },
  components: {
    NModal,
    NCard,
    NSpace,
    NButton,
    NAlert,
    CartSummary
  },
};
</script>

<style scoped>
.modal-card {
  width: 75vw;
  max-width: 600px;
  @media only screen and (max-width: 600px) {
    width: 99vw;
  }
}
.buy {
  position: sticky;
  top: 20px;
}

@media only screen and (max-width: 600px) {
  .buy {
    position: relative;
    top: auto;
  }
}
</style>
