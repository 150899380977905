<template>
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <div class="margin-bottom margin-large">
      <Header
        :itemLength="itemLength"
      />
    </div>
    <div class="cart-main">
      <div class="cart-items">
        <Suspense>
          <n-space  vertical space="large" v-if="campaignData === null || campaignData.status !== 'draft' || campaignData.items.length === 0" >
            <Empty/>
          </n-space>
          <n-space v-else vertical space="large">
            <CampaignForm />
            <ServiceList />
          </n-space>
          <template #fallback> Loading... </template>
        </Suspense>
      </div>
      <Buy />
    </div>
    <n-notification-provider v-if="apiError">
      <error-notification />
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import { darkTheme, NConfigProvider, NSpace, NNotificationProvider } from "naive-ui";
import Empty from "./components/Empty.vue";
import { useAppStore } from "@/store/app";
import { storeToRefs } from "pinia";
import ErrorNotification from "./components/ErrorNotification.vue";
export default defineComponent({
  setup() {
    const store = useAppStore();
    const { campaignData, apiError } = storeToRefs(store);

    const themeOverrides = {
      common: {
        primaryColor: "#8869a8",
        primaryColorHover: "#8869a8",
        primaryColorPressed: "#8869a8",
        heightLarge: "48px",
        borderRadius: "14px",
        fontSizeLarge: "20px",
        baseColor: "white",
      },
      DatePicker: {
        itemTextColorDisabled: "rgba(255, 255, 255, 0.82)",
      },
    };
    return {
      store,
      campaignData,
      apiError,
      darkTheme,
      themeOverrides,
    };
  },
  data() {
    return {
      items: [],
      metadata: {
        fanbaseInsights: null,
        genre: null,
        similarArtists: null,
        spotifyArtistId: null,
      },
    };
  },
  computed: {
    itemLength() {
      if(this.campaignData){
        return this.campaignData.items.length;
      }
      return 0;
    }
  },
  async created() {
    const params = new URLSearchParams(window.location.search);
    this.store.campaignId = params.get("campaignId")
      ? params.get("campaignId")
      : localStorage.getItem("campaignId");

    await this.store.hydrateCampaignList();

    if (this.store.campaignData !== null) {
        localStorage.setItem("campaignId", this.store.campaignId);
    }
  },
  components: {
    NConfigProvider,
    NSpace,
    Empty,
    ErrorNotification,
    NNotificationProvider
  },

});
</script>

<style scoped>
.buy {
  position: sticky;
  top: 20px;
}
</style>
