<template>
  <n-spin :show="loading">
  <n-card class="margin-bottom margin-large">
    <n-empty description="The cart is empty">
      <template #icon>
        <n-icon size="32">
          <ph-shopping-cart weight="bold" />
        </n-icon>
      </template>
      <template #extra>
        <n-button size="small" @click="startShopping"> Start Shopping </n-button>
      </template>
    </n-empty>
  </n-card>
  </n-spin>
</template>

<script>
  import { NCard, NEmpty, NIcon, NButton, NSpin } from "naive-ui";
  import { PhShoppingCart } from "phosphor-vue";
  export default {
    data() {
      return {
        loading : false,
      }
    },
    methods: {
      startShopping() {
        this.loading = true;
        setTimeout(function() {
          window.location.href = 'https://pitchbridge.com';
          this.loading = false;
        }, 3000);
      },
    },
    components: {
      NCard,
      NEmpty,
      NIcon,
      NButton,
      PhShoppingCart,
      NSpin
    }
  };
</script>

<style>
</style>
