<template>
  <div>
    <input
      type="hidden"
      role="uploadcare-uploader"
      :id="uuid"
    />
  </div>
</template>

<script>

import uploadcare from 'uploadcare-widget'
import {useAppStore} from '@/store/app.js'
import {storeToRefs} from "pinia";
import { v4 as uuidv4 } from 'uuid';

export default {
  setup() {
    const store = useAppStore();
    const { campaignData } = storeToRefs(store);
    return {
      store,
      campaignData,
      uuid: uuidv4()
    };
  },
  props: [
    'uploadcareId', 'contentMin'
  ],
  data() {
    return {
      fileCount : 0
    }
  },
  mounted() {
    const widget = uploadcare.Widget(`#${this.uuid}`, { publicKey: '504f17e0687b9e26fb17', multipleMin: this.contentMin });
    if(this.uploadcareId) {
      widget.value(this.uploadcareId);
    }

    widget.onUploadComplete((info) => {
      this.$emit('upload', info)
    })
  },
  methods: {
  },
}
</script>

<style>
.uploadcare--widget__button_type_open {
  background: #8C71B7;
  color: #fff;

}
.uploadcare--widget__button:focus, .uploadcare--widget__button_type_open:hover {
  background: #8C71B7;
}
.uploadcare--widget__button:focus {
    outline: 2px solid rgba(140, 113, 183, .5);
    outline-offset: 1px;
}
</style>
