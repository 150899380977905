<template>
  <div class="margin-bottom margin-large">
    <n-card
      class="card"
      :title="item.service ? item.service.name : ''"
      :segmented="{
        content: true,
        footer: 'soft',
      }"
    >
      <template #header-extra>
        <n-space size="large">
          <!-- Tag: Done -->
          <n-tag v-if="status" round :bordered="false" type="success">
            Done
            <template #icon>
              <n-icon>
                <ph-check-circle weight="fill" />
              </n-icon>
            </template>
          </n-tag>
          <n-tag v-else round :bordered="false" type="warning">
            Incomplete
          </n-tag>
          <n-dropdown
            trigger="click"
            :options="item.service.id === 4 ? deleteOption : options"
            @select="handleSelect"
          >
            <n-button
              ><n-icon size="24">
                <ph-dots-three-vertical weight="bold" /> </n-icon
            ></n-button>
          </n-dropdown>
        </n-space>
      </template>
      <template #action>
        <n-collapse>
          <n-collapse-item title="Budget Breakdown">
            <div v-if="item.service.noCommision">
              We do not take a commission on this item. Which means your total campaign budget is £{{cost}}
            </div>
            <div v-else>
              Your total cost is £{{ cost }}. We take a {{ comission }}%
              commission. Which means your campaign budget will be
              £{{ cost - cost * (comission / 100) }}
            </div>
          </n-collapse-item>
        </n-collapse>
      </template>
      <ServiceForm
        :itemId="item.itemId"
        @new-cost="handleCostChange"
        @form-status="updateStatus"
      />
    </n-card>
  </div>
</template>

<script>
import { h } from "vue";
import {
  PhTrash as DeleteIcon,
  PhCopy as CopyIcon,
  PhDotsThreeVertical,
  PhCheckCircle,
} from "phosphor-vue";
import {
  NIcon,
  NCard,
  NDropdown,
  NButton,
  NTag,
  NSpace,
  NCollapse,
  NCollapseItem,
} from "naive-ui";
import { useAppStore } from "../store/app";
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};

export default {
  setup() {
    const store = useAppStore();

    return {
      store,
      options: [
        {
          label: "Delete",
          key: "delete",
          icon: renderIcon(DeleteIcon),
        },
        {
          label: "Clone",
          key: "clone",
          icon: renderIcon(CopyIcon),
        },
      ],
      deleteOption: [
        {
          label: "Delete",
          key: "delete",
          icon: renderIcon(DeleteIcon),
        },
      ],
    };
  },
  props: ["item", "title"],
  data() {
    return {
      cost: null,
      status: false,
      comission: 25,
    };
  },
  computed: {
  },
  methods: {
    updateStatus(status) {
      this.status = status;
    },
    handleCostChange(newCost) {
      this.cost = newCost;
    },
    handleSelect(key) {
      switch (key) {
        case "delete":
          this.store.deleteCampaignItem(this.item.itemId);
          return;
        case "clone":
          this.store.cloneCampaignItem(this.item.itemId);
          return;
        default:
          return;
      }
    },
  },
  components: {
    NIcon,
    NCard,
    NDropdown,
    NButton,
    PhDotsThreeVertical,
    PhCheckCircle,
    NTag,
    NSpace,
    NCollapse,
    NCollapseItem,
  },
};
</script>

<style scoped>
.card {
  background-color: #28273f;
  border-radius: 1rem;
  border: none;
}
.background-none {
  background: transparent;
}
</style>
