<template>
  <n-form class="form">
    <n-space vertical size="large" class="n-space-vertical">
      <n-form-item
        v-if="[1, 2].includes(item.service.id)"
        :label="
          item.service.id === 1
            ? 'Song Link'
            : item.service.id === 2
            ? 'Youtube Link'
            : ''
        "
        class="formItem"
      >
        <n-space vertical class="n-space-vertical">
          <n-input
            :status="errors.song.status"
            placeholder="https://"
            v-model:value="metadata.content.links.song"
            @change="handleChange()"
          />
          <n-alert v-if="errors.song.state" title="Error" type="error">
            {{ errors.song.message }}
          </n-alert>
        </n-space>
      </n-form-item>
      <n-form-item
        label="Socials"
        class="formItem"
        v-if="item.service.id === 3"
      >
        <n-layout class="embedded">
          <n-form-item label="Facebook" class="formItem">
            <n-space vertical class="n-space-vertical">
              <n-input
                :status="errors.facebook.status"
                placeholder="https://"
                v-model:value="metadata.content.links.facebook"
                @change="handleChange()"
              />
              <n-alert v-if="errors.facebook.state" title="Error" type="error">
                {{ errors.facebook.message }}
              </n-alert>
            </n-space>
          </n-form-item>
          <n-form-item label="Instagram" class="formItem">
            <n-space vertical class="n-space-vertical">
              <n-input
                :status="errors.instagram.status"
                placeholder="https://"
                v-model:value="metadata.content.links.instagram"
                @change="handleChange()"
              />
              <n-alert v-if="errors.instagram.state" title="Error" type="error">
                {{ errors.instagram.message }}
              </n-alert>
            </n-space>
          </n-form-item>
          <n-form-item label="TikTok" class="formItem">
            <n-space vertical class="n-space-vertical">
              <n-input
                placeholder="https://"
                :status="errors.tiktok.status"
                v-model:value="metadata.content.links.tiktok"
                @change="handleChange()"
              />
              <n-alert v-if="errors.tiktok.state" title="Error" type="error">
                {{ errors.tiktok.message }}
              </n-alert>
            </n-space>
          </n-form-item>
        </n-layout>
      </n-form-item>
      <n-form-item
        v-if="item.service.id !== 4"
        label="Your Budget"
        class="formItem"
      >
        <n-space vertical class="n-space-vertical">
          <n-input-number
            v-model:value="cost.net"
            :status="errors.budget.status"
            @update:value="handleCostChange()"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <template #prefix> £ </template>
          </n-input-number>
          <n-alert v-if="errors.budget.state" title="Error" type="error">
            {{ errors.budget.message }}
          </n-alert>
        </n-space>
      </n-form-item>
      <n-form-item label="Campaign Start Date" class="formItem">
        <n-space vertical class="n-space-vertical">
          <n-date-picker
            v-model:value="metadata.startDate"
            placeholder="Select Start Date"
            format="dd-MM-yyyy"
            type="date"
            :status="errors.startDate.status"
            :is-date-disabled="allowStartDate"
            @update:value="handleChange()"
            :actions="[]"
          />
          <n-alert v-if="errors.startDate.state" title="Error" type="error">
            {{ errors.startDate.message }}
          </n-alert>
        </n-space>
      </n-form-item>
      <n-form-item label="Campaign End Date" class="formItem">
        <n-space vertical class="n-space-vertical">
          <n-date-picker
            v-model:value="metadata.endDate"
            placeholder="Select End Date"
            :disabled="disableEndDate"
            format="dd-MM-yyyy"
            type="date"
            :status="errors.endDate.status"
            :is-date-disabled="allowEndDate"
            @update:value="handleChange()"
            :actions="[]"
          />
          <n-alert v-if="errors.endDate.state" title="Error" type="error">
            {{ errors.endDate.message }}
          </n-alert>
        </n-space>
      </n-form-item>
      <n-form-item
        class="formItem"
        label="Upload 4 to 10 videos to be used in your ads (10 seconds each)"
        v-if="[1, 4].includes(item.service.id)"
      >
        <n-space vertical class="n-space-vertical">
          <ContentUpload
            :uploadcareId="metadata.content.uploadcareId"
            :contentMin="contentMin"
            @upload="onSuccess"/>
        </n-space>
      </n-form-item>
    </n-space>
  </n-form>
</template>

<script>
import {
  NForm,
  NFormItem,
  NDatePicker,
  NLayout,
  NInput,
  NInputNumber,
  NAlert,
  NSpace
  } from "naive-ui";
import { useAppStore } from "@/store/app";
import { storeToRefs } from "pinia";
import ContentUpload from "./ContentUpload.vue";
import {debounce} from "lodash";
import {BUDGET_EXCEED, BUDGET_LESS, BUDGET_IN_DECIMAL} from "@/ErrorType.js";
const regix = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export default {
  setup() {
    const store = useAppStore();
    const {campaignData} = storeToRefs(store);

    return {
      store,
      campaignData,
    };
  },
  props: [ 'itemId'],
  data() {
    return {
      waitForCall : false,
      errors: {
        song: {
          state: false,
          message: "Please Enter valid URL",
          status: ""
        },
        facebook: {
          state: false,
          message: "Please Enter valid Facebook URL",
          status: ""
        },
        instagram: {
          state: false,
          message: "Please Enter valid Instagram URL",
          status: ""
        },
        tiktok: {
          state: false,
          message: "Please Enter valid TikTok URL",
          status: ""
        },
        budget: {
          state: false,
          message: "",
          status: ""
        },
        endDate: {
          state: false,
          message: "",
          status: ""
        },
        startDate: {
          state: false,
          message: "",
          status: ""
        },
        upload: {
          state: false,
          message: "",
          status: ""
        },
      },
    };
  },
  computed: {
    disableEndDate() {
      return this.metadata.startDate === null ? true : false;
    },
    cost() {
      return {
        net :  this.item ? this.item.cost.net : ""
      }
    },
    item () {
      return this.campaignData.items.find(item => item.itemId === this.itemId);
    },
    metadata() {
      return this.item ? this.item.metadata : {};
    },
    contentMin() {
      return this.item ? this.item.service.options.contentMin : 0 ;
    }
  },
  mounted() {
    this.loadFormState();
    const valid = this.validateForm()
    this.$emit("new-cost", this.cost.net);
    this.$emit("form-status", valid);
  },

  methods: {
    async onSuccess(uploadItem) {
      const uploadCareId = uploadItem.uuid;
      this.metadata.content.uploadcareId = uploadCareId;
      const body = {
        itemId: this.itemId,
        metadata: this.metadata
      };
      await this.store.updateServiceFormData(body);
      this.$emit("form-status", this.validateForm());
    },
    async handleChange() {
      const body = {
        itemId: this.item.itemId,
        metadata: this.metadata,
      };
      await this.store.updateServiceFormData(body);
      this.$emit("form-status", this.validateForm());
    },
    handleCostChange: debounce(async function () {
      const body = {
        itemId: this.item.itemId,
        cost: this.cost,
      };
      await this.store.updateServiceCost(body);
      this.$emit("new-cost", this.cost.net);
      this.$emit("form-status", this.validateForm());
    }, 500),

    allowStartDate(timestamp) {
      const allowedDate = new Date();
      allowedDate.setDate(allowedDate.getDate() + 3);

      if (this.metadata.endDate !== null) {
        return timestamp < allowedDate || timestamp > this.metadata.endDate;
      }
      return timestamp < allowedDate;
    },
    allowEndDate(timestamp) {
      return timestamp <= this.metadata.startDate;
    },
    validateURL(value) {
      if(this.metadata.content && this.metadata.content.links[value] !== "" &&  this.metadata.content.links[value] !== null && this.metadata.content.links[value] !== "undefined") {
        const isInValid = !regix.test(this.metadata.content.links[value]);
        this.errors[value]["state"] = isInValid;
        this.errors[value]["status"] = isInValid ? "error" : "";
      }
    },
    validateDate(property) {
      const isValid = this.metadata[property] === null;
      this.errors[property].state = isValid ? true : false;
      this.errors[property].message = isValid
        ? "Start Date can not be empty"
        : "";
      this.errors[property].status = isValid ?  "error" : ""
    },
    validateForm() {
      if(this.metadata){
        const {startDate, endDate} = this.metadata;
        const {links, uploadcareId} = this.metadata.content ? this.metadata.content : {};
        const {song, facebook, instagram, tiktok} = links ? links : {};
        const {id} = this.item ? this.item.service : 0;
        const cost = this.item ? this.item.cost : "0";
        switch (id) {
          case 1:
          return this.isCommonFieldsValid(cost, startDate, endDate)
                && song && uploadcareId && !this.errors.song.state
                ? true : false;
          case 2:
            return this.isCommonFieldsValid(cost, startDate, endDate)
                && song && !this.errors.song.state
                ? true : false;
          case 3:
            return this.isCommonFieldsValid(cost, startDate, endDate)
                && facebook && instagram && tiktok
                && !this.errors.facebook.state && !this.errors.instagram.state && !this.errors.tiktok.state
                ? true: false;
          case 4:
            return startDate && endDate && uploadcareId
                && !this.errors.startDate.state && !this.errors.endDate.state
                ? true : false;
          default:
            break;
        }
      }
    },
    isCommonFieldsValid(cost, startDate, endDate) {
      return cost.net && !cost.net.toString().includes(".")
          && startDate && endDate
          && !this.errors.budget.state && !this.errors.startDate.state && !this.errors.endDate.state
           ? true: false;
    },

    validateBudgetField() {
      const cost = this.item ? this.item.service.cost[0] : {};
      if (this.cost.net < cost.min) {
        this.setBudgetErrorObject(true, `${BUDGET_LESS}${cost.min}`, "error");
      } else if (cost.max && this.cost.net > cost.max) {
        this.setBudgetErrorObject(true, `${BUDGET_EXCEED}${cost.max}`, "error");
      } else if(this.cost.net.toString().includes(".")) {
        this.setBudgetErrorObject(true, `${BUDGET_IN_DECIMAL}`, "error");
      } else {
        this.setBudgetErrorObject(false, "", "");
      }
    },
    setBudgetErrorObject(state, message, status) {
      this.errors.budget.state = state;
      this.errors.budget.message = message;
      this.errors.budget.status = status;
    },
    loadFormState() {
      if(this.metadata && this.item) {
        switch (this.item.service.id) {
          case 1:
            this.validateURL("song");
            this.validateBudgetField();
            break;
          case 2:
            this.validateURL("song");
            this.validateBudgetField();
            break;
          case 3:
            ["facebook", "instagram", "tiktok"].forEach(field => this.validateURL(field))
            this.validateBudgetField()
            break;
          default:
            break;
        }
      }
    },
  },
  watch: {
    "metadata.content.links.song"() {
      this.loadFormState()
      this.$emit("form-status", this.validateForm());
    },

    "metadata.content.links.facebook"() {
      this.loadFormState()
      this.$emit("form-status", this.validateForm());
    },

    "metadata.content.links.instagram"() {
      this.loadFormState()
      this.$emit("form-status", this.validateForm());
    },
    "metadata.content.links.tiktok"() {
      this.loadFormState()
      this.$emit("form-status", this.validateForm());
    },
    "cost.net"() {
      this.validateBudgetField()
    },
  },
  components: {
    NForm,
    NFormItem,
    NDatePicker,
    NLayout,
    NInput,
    NInputNumber,
    NAlert,
    NSpace,
    ContentUpload,
  },
};
</script>

<style scoped>
.form {
  display: block;
  width: 100%;
}
.formItem {
  width: 100%;
}
.embedded {
  background-color: transparent;
  padding: 1rem;
  border: 1px solid rgba(97, 97, 97, 0.52);
  border-radius: 0.5rem;
  width: 100%;
}
.n-space-vertical {
  width: 100%;
}
</style>
