import { defineStore } from "pinia";
import axios from "@/client.js";

export const useAppStore = defineStore("app", {
  state: () => ({
    campaignData: null,
    campaignId: null,
    loading: false,
    apiError: null
  }),
  actions: {
    async hydrateCampaignList() {
      try {
        this.loading = true;
        const result = await axios.get(`/campaign?campaignId=${this.campaignId}`);
        this.campaignData = result.data;
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if (error.response) {
          this.apiError = error.response.data.message;
          console.log(error.response.data.message);
        }
      }
    },

    async cloneCampaignItem(itemId) {
      try {
        this.loading = true;
        const result = await axios.post("campaign/item/duplicate", {
          campaignId: this.campaignId,
          itemId: itemId
        });
        this.campaignData = result.data;
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if(error.response) {
          this.apiError = error.response.data.message;
        }
      }
    },

    async deleteCampaignItem(itemId) {
      try {
        this.loading = true;
        const result = await axios.post("campaign/item/remove", {
          campaignId: this.campaignId,
          itemId: itemId
        });
        this.campaignData = result.data;
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if(error.response) {
          this.apiError = error.response.data.message;
        }
      }
    },

    async updateCampaignDetail(metadata) {
      try {
        const reqPayload = {
          campaignId: this.campaignId,
          metadata
        };
        this.loading = true;
        const result = await axios.post("/campaign/metadata", reqPayload);
        if (result.data) {
          this.campaignData = result.data;
        }
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if(error.response) {
          this.apiError = error.response.data.message;
        }
      }
    },

    async updateServiceFormData(payload) {
      try {
        const { itemId, metadata } = payload;
        const reqPayload = {
          campaignId: this.campaignId,
          itemId,
          metadata
        };
        this.loading = true;
        const result = await axios.post("/campaign/item/metadata", reqPayload);
        if (result.data) {
          this.campaignData = result.data;
        }
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if(error.response) {
          this.apiError = error.response.data.message;
        }
      }

    },

    async updateServiceCost(payload) {
      try {
        const { itemId, cost } = payload;
        const reqPayload = {
          campaignId: this.campaignId,
          itemId,
          cost
        };
        this.loading = true;
        const result = await axios.post("/campaign/item/cost", reqPayload);
        if (result.data) {
          this.campaignData = result.data;
        }
        this.loading = false;
        this.apiError = null;
      } catch (error) {
        if(error.response) {
          this.apiError = error.response.data.message;
        }
      }
    }
  }
});
