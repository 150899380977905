<template>
  <n-list
    class="order-box_component order-box_content-wrapper">
    <template #header>
      <h6>Cart Summary</h6>
    </template>
    <div v-if="campaignData">
      <div v-if="campaignData.status === 'draft'">
        <n-list-item
          v-for="item in campaignData.items"
          :key="item.itemId">
          <template #suffix>
            <label>£{{item.cost.net}}</label>
          </template>
          <n-thing :title="item.service.name"></n-thing>
        </n-list-item>
      </div>
      <n-list-item>
        <template #suffix>
          <label>£{{totalAmount}}</label>
        </template>
        <n-thing title="Total Cost:"></n-thing>
      </n-list-item>
    </div>
    <!-- <template #footer>
      <h6>Total Cost: £ {{totalAmount}}</h6>
    </template> -->
  </n-list>
</template>

<script>
import {
  NList,
  NListItem,
  NThing
} from "naive-ui";
import { useAppStore } from "../store/app";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const store = useAppStore()
    const {campaignData} = storeToRefs(store);
    return {
      store,
      campaignData
    }
  },
  computed: {
    totalAmount() {
      if(this.campaignData && this.campaignData.status === "draft") {
        const amount = this.campaignData.items.reduce((partialSum, item) => {
          return partialSum + item.cost.net
        }, 0);
        return amount;
      }
      return 0;
    }
  },
  components:{
    NList,
    NListItem,
    NThing
  }
}
</script>
