<template>
  <n-space>
  </n-space>
</template>

<script scoped>
import { useNotification } from 'naive-ui'
import { defineComponent } from 'vue'
import { useAppStore } from "@/store/app";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup () {
    const notification = useNotification()
    const store = useAppStore();
    const { apiError } = storeToRefs(store);

    return {
      notification,
      apiError
    }
  },
  props: ["message"],
  mounted() {
    this.notification.warning({
      content: this.apiError,
      keepAliveOnHover: true,
      onClose: () => {
        this.apiError = null;
        console.log("closed",  this.apiError);
      },
    });
  }
})
</script>
