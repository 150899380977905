<template>
  <img class="logo" src="@/assets/logo.png" />
  <a href="https://pitchbridge-c3f8e0.webflow.io/" class="button-link with-icon">
    <n-icon size="24">
      <ph-arrow-left />
    </n-icon>
    <div>Back to Website</div>
  </a>
  <n-space>
    <h1>Cart</h1>
    <n-tag>{{ itemLength }}</n-tag>
  </n-space>
</template>

<script>
import { NIcon, NTag, NSpace } from "naive-ui";
import { PhArrowLeft } from "phosphor-vue";

export default {
  props: ["itemLength"],
  components: {
    NIcon,
    PhArrowLeft,
    NTag,
    NSpace,
  },
};
</script>

<style scoped>
.logo {
  max-width: 100vw;
  margin: auto;
  display: block;
}
</style>
